import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import PageHeader from '../../containers/PageHeader'
import PageFooter from '../../containers/PageFooter'

import './styles.sass'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <div className={`pagetype-${frontmatter.type}`}>
      <Helmet>
        <title>{frontmatter.title} | Ironstar - Premium Drupal Hosting</title>
        <meta name="description" content={frontmatter.description} />
        <meta name="keywords" content={frontmatter.keywords} />
      </Helmet>

      <PageHeader />
      <main className="page-container container">
        <section className="page__header">
          <div className="row">
            <h1 className="col h1">{frontmatter.title}</h1>
          </div>
        </section>
        <div className="row">
          {frontmatter.hasAside && (
            <aside className="col-lg-3 page__aside"></aside>
          )}
          <div className="col-lg-9 page__content">
            <section
              className="page__content__inner"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </main>
      <PageFooter />
    </div>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        description
        keywords
        type
      }
    }
  }
`
